import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navigation from './Navigation';
import './homeStyles.css';

const OurTeam = () => {
  const teamMembers = [
    {
      name: 'Veerendra Kumar Patil',
      role: "Managing Partner",
      description: "Our Founder and Creative Director, is a highly influential figure in the Indian animation industry , having co-founded Paprikaas Animation Studios (now Technicolor India) and Xentrix Studios."
    },
    {
      name: 'Venkataraman Koteeshwarn',
      role: "Managing Partner",
      description:"Our Co-founder and Art Director, brings his unconventional style and expertise in high-profile projects such as 'Lego Dreamzzz'"
    },
    {
      name: 'Ishani Priyamvada',
      role: "Producer"
    }
  ];

  return (
    <div className="app-wrapper">
      <Navigation />
      <Container 
        fluid 
        className="d-flex align-items-center justify-content-center"
        style={{
          width: '100%',
          height: "calc(100vh - 1rem)",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          padding: '0',
          margin: '0'
        }}
      >
        <Row 
          className="w-100 text-center"
          style={{
            justifyContent: 'center'
          }}
        >
          <Col 
            xs={12} 
            sm={10} 
            md={8} 
            lg={6} 
            className="d-flex flex-column align-items-center justify-content-center"
          >
            {teamMembers.map((member, index) => (
              <div 
                key={index} 
                className="w-100 text-center mb-5"
                style={{ 
                  maxWidth: '100%'
                }}
              >
                <div 
                  className="d-flex flex-column align-items-center"
                  style={{ 
                    width: '100%'
                  }}
                >
                  <h5 
                    className="text-white font-weight-bold mb-2 member-name"
                    style={{ 
                      fontSize: 'calc(1.5rem + 0.3vw)',
                      textAlign: 'center'
                    }}  
                  >
                    {member.name}
                    {member.description && (
                      <span 
                        className="d-block text-white-50 member-description"
                        style={{ 
                          fontSize: 'calc(0.8rem + 0.3vw)',
                          fontWeight: 'normal',
                          marginTop: '0.5rem',
                          maxWidth: '100%',
                          lineHeight:'1.7rem'
                        }}
                      >
                        - {member.description}
                      </span>
                    )}
                  </h5>
                  {member.role && (
                    <p 
                      className="text-info member-role"
                      style={{ 
                        fontSize: 'calc(1rem + 0.2vw)'
                      }}
                    >
                      {member.role}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurTeam;