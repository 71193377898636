import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import {Typography} from '@mui/material'
import './homeStyles.css';
import Navigation from './Navigation';
import zebuFilms from './assets/ZEBU_Logo.png';

const Home = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="app-wrapper">
      <Navigation/>
      <Container 
        fluid 
        className="position-relative d-flex align-items-center justify-content-center vh-100"
      >
        <Row className="justify-content-center text-center w-100 align-items-center">
          <Col 
            xs={12} 
            sm={10} 
            md={8} 
            lg={8} 
            xl={8} 
            xxl={12} 
            className="text-center px-3 px-sm-4 px-md-5"
          >
            <img 
              className="zebu-home-image img-fluid mb-3 mb-md-5" 
              src={zebuFilms} 
              alt='Zebu Films Logo'
              style={{
                maxWidth: '100%',
                height: 'auto',
                // maxHeight: '250px',
                // objectFit: 'contain'
              }}
            />
           
            <Typography 
              variant='body1' 
              className='mb-3 mt-3 mt-md-4' 
              style={{
                color:"rgba(255,255,255,0.8)", 
                fontSize:{
                  xs: '0.9rem',
                  sm: '1rem',
                  md: '1.1rem'
                }, 
                maxWidth:"50rem", 
                margin: "0 auto", 
                textAlign: "center"
              }}
            > 
              Stay tuned for something amazing. We're crafting an extraordinary experience just for you.
            </Typography>
            <Button 
              className='subscribe-btn mt-2 mt-md-3'  
              style={{
                background: "rgba(0, 149, 255, 0.1)",
                border: "none",
                borderRadius:"1rem", 
                padding: "0.6rem 1.5rem",
                fontSize: {
                  xs: '0.8rem',
                  md: '1rem'
                },
                fontWeight:"600", 
                letterSpacing:"0.1rem", 
                transition:"all 0.3s ease"
              }}
            >
              SUBSCRIBE
            </Button>
            <div className="dots mt-3 mt-md-5">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home