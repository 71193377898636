import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Typography } from '@mui/material';
import Navigation from './Navigation';
import './homeStyles.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted', formData);
  };

  return (
    <div className="app-wrapper">
      <Navigation />
      
      <Container 
        fluid 
        className="d-flex align-items-center justify-content-center vh-100"
      >
        <Row className="w-100 justify-content-center align-items-center g-0">
          {/* Contact Information Column */}
          <Col 
            xs={12} 
            md={5} 
            className="text-center text-md-start px-3 px-md-5 py-3 py-md-5"
          >
            <Typography 
              variant="h3" 
              className="mb-3 form-head" 
              style={{ 
                letterSpacing: '0.2rem', 
                color: 'white',
                fontSize: {
                  xs: '1rem',
                  md: '2rem'
                }
              }}
            >
              Zebu Films
            </Typography>

            <Typography 
              variant="body1" 
              className="mt-3 address-body" 
              style={{ 
                color: 'rgba(255,255,255,0.8)', 
                lineHeight: '1.6',
                fontSize: {
                  xs: '0.9rem',
                  md: '1rem'
                }
              }}
            >
              Vista B-1301, Godrej United, Whitefield Main Road,<br />
              Opposite to Brigade Metropolis, Mahadevapura, Bangalore 560048<br />
            </Typography>
          </Col>

          {/* Form Column */}
          <Col 
            xs={12} 
            md={5} 
            className="px-3 px-md-5 py-3 py-md-5"
          >
            <Typography 
              variant="h3" 
              className="mb-4 text-center form-head" 
              style={{ 
                letterSpacing: '0.5rem', 
                color: 'white',
                fontSize: {
                  xs: '1.5rem',
                  md: '2rem'
                }
              }}
            >
              CONTACT US
            </Typography>

            <Form onSubmit={handleSubmit}>
              <Row className="g-3">
                <Col xs={12} md={6}>
                  <Form.Control 
                    type="text" 
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name" 
                    style={{
                      background: 'rgba(255,255,255,0.1)',
                      borderColor: 'rgba(0,149,255,0.3)',
                      color: 'white',
                      borderRadius: '10px',
                    }}
                    className="form-control-custom"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control 
                    type="email" 
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email" 
                    style={{
                      background: 'rgba(255,255,255,0.1)',
                      borderColor: 'rgba(0,149,255,0.3)',
                      color: 'white',
                      borderRadius: '10px'
                    }}
                    className="form-control-custom"
                  />
                </Col>
                <Col xs={12}>
                  <Form.Control 
                    type="tel" 
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Your Phone Number" 
                    style={{
                      background: 'rgba(255,255,255,0.1)',
                      borderColor: 'rgba(0,149,255,0.3)',
                      color: 'white',
                      borderRadius: '10px'
                    }}
                    className="form-control-custom"
                  />
                </Col>
                <Col xs={12}>
                  <Form.Control 
                    as="textarea" 
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={5} 
                    placeholder="Your Message" 
                    style={{
                      background: 'rgba(255,255,255,0.1)',
                      borderColor: 'rgba(0,149,255,0.3)',
                      color: 'white',
                      borderRadius: '10px'
                    }}
                    className="form-control-custom"
                  />
                </Col>
                <Col xs={12} className="text-center">
                  <Button 
                    type="submit"
                    className='subscribe-btn mt-2 mt-md-3'  
                    style={{
                      background: "rgba(0, 149, 255, 0.1)",
                      border: "none",
                      borderRadius:"1rem", 
                      padding: "0.6rem 1.5rem",
                      fontSize: {
                        xs: '0.8rem',
                        md: '1rem'
                      },
                      fontWeight:"600", 
                      letterSpacing:"0.1rem", 
                      transition:"all 0.3s ease"
                    }}
                  >
                    SEND MESSAGE
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;