import React from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import zebuFilms from './assets/ZEBU_Logo.png';
import { Link } from 'react-router-dom';
import  './homeStyles.css';
const Navigation = () => {
  return (
    <Navbar
      expand="lg"
      variant="dark"
      style={{ background: 'transparent', width: '90%', marginLeft: '2%', zIndex: '1030' }} 
      className="position-relative"
    >
      <Container fluid>
        {/* Logo */}
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center nav-bar-logo">
          <img
            src={zebuFilms}
            className="img-fluid"
            alt="Zebu Animation Logo"
            style={{
              maxWidth: '13rem',      
              width: '100%',         
              height: 'auto',         
              maxHeight: '5rem',      
              objectFit: 'contain',   
            }}
          />
        </Navbar.Brand>

        {/* Toggle button for mobile screens */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Collapsible navigation links */}
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          {/* Nav links with background on small screens */}
          <Nav
            className="text-center w-100 d-lg-none" // Hidden on large screens
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }} // Background for small screens
          >
            <Nav.Link as={Link} to="/" className="px-3">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="px-3">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/our-works" className="px-3">
              Our Works
            </Nav.Link>
            <Nav.Link as={Link} to="/our-team" className="px-3">
              Our Team
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className="px-3">
              Contact
            </Nav.Link>
          </Nav>

          {/* Nav links for large screens (without background) */}
          <Nav className="text-center d-none d-lg-flex">
            <Nav.Link as={Link} to="/" className="px-3">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="px-3">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/our-works" className="px-3">
              Our Works
            </Nav.Link>
            <Nav.Link as={Link} to="/our-team" className="px-3">
              Our Team
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className="px-3">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {/* Button section aligned to the right */}
        {/* <div className="ms-auto d-flex align-items-center">
          <Button variant="outline-light" style={{ borderRadius: '1rem' }} className="d-none d-lg-block">
            Sign up
          </Button>
          <Button variant="outline-light" style={{ borderRadius: '1rem' }} className="d-lg-none">
            Sign up
          </Button>
        </div> */}
      </Container>
    </Navbar>
  );
};

export default Navigation;
