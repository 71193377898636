import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import OurTeam from './OurTeam';
import Ourwork from './Ourwork';
// import Navigation from './Navigation'; 

function App() {
  return (
    <Router>
      <div className="App">
      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/our-team' element={<OurTeam/>} />
          <Route path='/our-works' element={<Ourwork/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;