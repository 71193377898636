import React from 'react';
import { Container, Row, Col,OverlayTrigger } from 'react-bootstrap';
import { Typography, Tooltip } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './homeStyles.css';
import Navigation from './Navigation';



const About = () => {
  const teamMembers = [
    {
      name: 'Veerendra Kumar Patil',
      image: "https://www.zebuanimation.com/img/1.jpg",
      linkedIn: "https://www.linkedin.com/in/veerendra-p-33a0672a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      description: "Founder & Creative Director\n\nWith over two decades in animation, Veerendra Patil is one of India's most influential creative executives. A co-founder of Paprikaas (now Technicolor India) and Xentrix Studios, he has scaled teams to over 7,000 and delivered 2,000+ hours of TV, film, and games. Veerendra has been pivotal in transforming India's animation industry, collaborating with global giants like Technicolor, DreamWorks, and EA. His notable projects include Iron Man: Armored Adventures, Shimmer & Shine, and Max Steel. As Founder of Zebu Animation Studios, he is creating three original Indian-inspired IPs with universal appeal."
    },
    {
      name: 'Venkataraman Koteeshwarn',
      image: "https://www.zebuanimation.com/img/3.jpg",
      linkedIn: "https://www.linkedin.com/in/venky-ramanan-k-38b7928a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      description: "Founder & Art Director\n\nVenkataramanan K brings over two decades of artistic excellence to Zebu Animation Studios. Known for his work on Beware the Batman, LEGO Ninjago, Transformers: Robots in Disguise, and Kung Fu Panda: Legends of Awesomeness, Venky's expertise in visual storytelling has earned global recognition. His keen eye for detail and innovative approach continues to define Zebu's creative vision."
    }
  ];

  return (
    <div style={{ 
      background: 'linear-gradient(to bottom, #000c02 0%, #002109 100%)', 
      minHeight: '100vh',
      color: 'white'
    }}>
      <Navigation />
      <Container fluid className="py-5">
        <h1 className="text-center mb-5 fw-bold">About Us</h1>
        
        {/* Company Description */}
        <Row className="justify-content-center mb-5">
          <Col xs={11} md={10} lg={8} xl={7} xxl={6}>
            <div className="text-center">
              <p className="mb-2" style={{ fontSize: '1.1rem', lineHeight: '1.8' }}>
                Zebu Films is an innovative powerhouse in the world of animation, renowned for its groundbreaking work and creative excellence. Established in 2019, Zebu Films has rapidly emerged as a leader in the animation industry, captivating audiences worldwide with its visually stunning and emotionally compelling content. At the heart of the studio's philosophy is a commitment to <strong>crafting immersive worlds and compelling narratives that not only entertain but also inspire and provoke thought. The fundamentals on which the entity stands is Integrity, Moral Values, Empathy, Compassion etc.,</strong>
              </p>
              <p className="mb-1" style={{ fontSize: '1.1rem', lineHeight: '1.8' }}>
                Led by a team of visionary directors, artists, animators, and storytellers. Zebu Films is committed to pushing the boundaries of creativity and storytelling through animation in India under the log line <strong>Make in India for World.</strong>
              </p>
            </div>
          </Col>
        </Row>

        {/* Founders Section */}
        <h2 className="text-center mb-5 fw-bold">Founders</h2>
        <Row className="justify-content-center g-4">
          {teamMembers.map((member, index) => (
            <Col key={index} xs={12} sm={6} md={5} lg={4} xl={3} className="text-center">
              <div className="mb-4">
                <div style={{
                  width: '200px',
                  height: '200px',
                  margin: '0 auto',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  border: '4px solid rgba(255, 255, 255, 0.1)'
                }}>
                  <img
                    src={member.image}
                    alt={member.name}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </div>
                <div className="mt-4 mb-3 d-flex align-items-center justify-content-center gap-2">
                  <h3 style={{ fontSize: '1.4rem', fontWeight: '600', margin: 0 }}>
                    {member.name}
                  </h3>
                  <Tooltip
                        title={
                          <Typography 
                            variant="body2" 
                            style={{ 
                              fontSize:'.589rem',
                              color: 'white', 
                              background: 'rgba(0,0,0,0.6)',
                              whiteSpace: 'pre-wrap',
                              maxWidth: '300px',
                              maxHeight: '300px',
                              padding: '10px',
                              borderRadius: '8px',
                              boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                            }}
                          >
                            {member.description}
                          </Typography>
                        }
                        placement="top"
                        arrow
                      >
                        <InfoOutlinedIcon 
                          style={{ 
                            color: 'white', 
                            cursor: 'pointer',
                            fontSize: '20px'
                          }}
                        />
                      </Tooltip>
                </div>
                <a
                  href={member.linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-inline-flex align-items-center justify-content-center p-2 rounded"
                  style={{
                    background: 'linear-gradient(135deg, #0077B5 0%, #0A66C2 100%)',
                    transition: 'all 0.3s ease',
                    marginTop: '0.5rem'
                  }}
                  onMouseOver={e => e.currentTarget.style.transform = 'translateY(-2px)'}
                  onMouseOut={e => e.currentTarget.style.transform = 'translateY(0)'}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="white"
                  >
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                  </svg>
                </a>
              </div>
            </Col>
          ))}
        </Row>

        {/* Company Story */}
        <Row className="justify-content-center mt-5">
          <Col xs={11} md={10} lg={8} xl={7} xxl={6}>
            <div className="text-center">
              <p style={{ 
                fontSize: '1.2rem',
                lineHeight: '1.2',
                color: 'rgba(255, 255, 255, 0.9)'
              }}>
                We share a common dream; to create films, and talk about stories that matter,
                that is what brought us together. <br />With over 20 years of experience up our sleeves,
                we are starting a brand new company, to help us fulfil that dream.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );

}
export default About;