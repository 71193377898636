import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navigation from './Navigation';
import './homeStyles.css';

const Ourwork = () => {
  return (
    <div className='app-wrapper'>
      <Navigation />
      <div 
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 15rem)',
          width: '100%',
          position: 'absolute',
          top: '12rem',
          left: 0,
          padding: '0 20px',
        }}
      >
        <Container fluid className="text-center">
          <Row className="mb-3 ">
            <Col>
              <p  className='our-work-intro'
                style={{ 
                  fontFamily: "'Courier New', monospace", 
                  color: '#ffffff', 
                  opacity: 0.9,
                  fontSize: '1.275rem'
                }}
              >
                Made in Zebu! with artists from all over the world
              </p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <h3 
              className='work-header'
                style={{ 
                  color: '#ffffff', 
                  fontSize: '1.5rem', 
                  lineHeight: 1.6,
                  fontWeight: '400',
                }}
              >
                THE NEW FEATURE FILM, PHARRELL WILLIAMS LEGO PIECE BY PIECE
              </h3>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="iframe-col">
              <div 
                className="iframe-container"
                style={{
                  borderRadius: '16px',
                  overflow: 'hidden',
                  boxShadow: '0 10px 30px rgba(0,0,0,0.3)',
                  transition: 'transform 0.3s ease',
                }}
              >
                <iframe 
                  width="100%" 
                  height="450"
                  src="https://www.youtube.com/embed/7Bc6trBc1kc?si=GcZtwVhNL-Ba_HSr&amp;controls=0" 
                  title="YouTube video player" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  referrerPolicy="strict-origin-when-cross-origin" 
                  allowFullScreen
                  style={{
                    border: 'none',
                    display: 'block'
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Ourwork;